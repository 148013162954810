import { sortTypes } from '../studio/schemas/objects/shop-sort'

// Create our sorting fallback titles from Sanity
const sortFallbacks = sortTypes
  .map((type) => `type == "${type.value}" => "${type.title}"`)
  .join(',')

// Construct our "home" and "error" page GROQ
export const homeID = `*[_type=="generalSettings"][0].home->_id`
export const exploreID = `*[_type=="generalSettings"][0].explore->_id`
export const articlesID = `*[_type=="generalSettings"][0].articles->_id`
export const errorID = `*[_type=="generalSettings"][0].error->_id`

// Construct our "image meta" GROQ
export const imageMeta = `
  "alt": coalesce(alt, asset->alt),
  asset,
  crop,
  customRatio,
  hotspot,
  "id": asset->assetId,
  "type": asset->mimeType,
  "aspectRatio": asset->metadata.dimensions.aspectRatio,
  "lqip": asset->metadata.lqip
`

// Construct our "artist" GROQ
export const artist = `
  title,
  "type": _type,
  "slug": slug.current,
  country,
  shortText,
  photo
`

// Construct our "page" GROQ
export const page = `
  title,
  "type": _type,
  "slug": slug.current,
  "isHome": _id == ${homeID},
  "isStyle": defined(style->slug.current),
  "isCurated": curated,
  "isOverlay": openInOverlay,
  category->{
    "slug": slug.current
  },
  centered,
  slug.current == "artists" => {
    "artists": *[_type == "artist"]{
      ${artist},
      "photo": coalesce(photo, *[_type == "product" && artist._ref == ^._id && wasDeleted != true && isDraft != true] | order(_updatedAt desc) [0].shopifyImageSrc)
    }
  }
`

// Construct our "page" GROQ
export const article = `
  "type": _type,
  "slug": slug.current,
  tags[]->{title, "slug": slug.current},
  title,
  summary,
  category,
  "publishDate": coalesce(publishDate, _createdAt),
  authorName,
  authorTitle,
  thumbnail{
    ${imageMeta}
  }
`

// Construct our "link" GROQ
export const link = `
  _key,
  _type,
  title,
  url,
  "page": page->{
    ${page}
  },
  urlParams
`

// Construct our "portable text content" GROQ
export const ptContent = `
  ...,
  markDefs[]{
    ...,
    _type == "link" => {
      "url": @.url,
      "isButton": @.isButton,
      "styles": @.styles{style, isLarge, isBlock},
      "page":@.page->{
        ${page}
      }
    }
  },
  _type == "photo" => {
    ${imageMeta}
  }
`

export const productSettings = (preview) => `
  "type": _type,
  "paginationLimit": *[_type == "shopSettings"][0].paginationLimit,
  "sortOptions": *[_type == "shopSettings"][0].shopSort[]{
    "slug": type,
    "title": coalesce(title, select(
      ${sortFallbacks}
    ))
  },
  "styles": *[_type == 'productStyle'${
    preview?.active ? ' && _id in path("drafts.**")' : ''
  }] | order(title asc){
    title,
    "slug": slug.current
  },
  "colors": *[_type == 'solidColor'${
    preview?.active ? ' && _id in path("drafts.**")' : ''
  }] | order(title asc){
    title,
    "slug": color.hex,
  },
  "artists": *[_type == 'artist'${
    preview?.active ? ' && _id in path("drafts.**")' : ''
  }] | order(title asc) {
    ${artist}
  },
  "noFilterResults": *[_type == "shopSettings"][0].noFilterResults[]{
    ${ptContent}
  }
`

// Construct our "product" GROQ
export const product = `
  {
    isDraft,
    "type": _type,
    "publishDate": coalesce(publishDate, _createdAt),
    "slug": slug.current,
    order,
    hideOnIndex,
    category->{
      title,
      "slug": slug.current,
      suggestFrames,
      showShippingDisclaimer,
    },
    "id": productID,
    title,
    price,
    sku,
    restrictFrameTypes[]->{
      "slug": slug.current
    },
    frameType->{
      title,
      "slug": slug.current,
      portraitOnly,
      hidePoster
    },
    styles[]->{
      title,
      "slug": slug.current
    },
    colors[]->{
      title,
      "slug": color.hex,
    },
    artist->{
      ${artist}
    },
    "photo": shopifyImageSrc,
    "gallery": galleryPhotos[]{
      ...,
      photo {
        ${imageMeta}
      }
    },
    "altPhotos": productPhotos[]{
      forOption,
      photo {
        ${imageMeta}
      }
    },
    "description": {
      "id": "about",
      "title": "About",
      "content": description
    },
    "otherDescriptions": *[_type == "productDescription" && category->.slug.current == ^.category->.slug.current][0].descriptions[]{
      title,
      "content": body[]{
        ${ptContent}
      }
    },
    inStock,
    lowStock,
    useGallery,
    surfaceOption,
    options[]{
      name,
      position,
      values[]
    },
    "variants": *[_type == "productVariant" && productID == ^.productID && wasDeleted != true && isDraft != true]{
      "id": variantID,
      title,
      price,
      comparePrice,
      inStock,
      lowStock,
      sku,
      "photo": shopifyImageSrc,
      "gtmData": {
        "id": variantID,
        productID,
        sku,
        "title": ^.title,
        "variant": variantTitle,
        "type": ^.category->slug.current,
        "value": price,
      },
      options[]{
        name,
        position,
        value
      },
      seo,
      "category": ^.category->{
        title,
        "slug": slug.current,
        suggestFrames,
        showShippingDisclaimer,
      },
    },
    "filters": filters[]{
      "slug": filter->slug.current,
      forOption
    }
  }
`

export const story = `{
  "type": _type,
  category->{
    "slug": slug.current
  },
  title,
  "slug": slug.current,
  "publishDate": coalesce(publishDate, _createdAt),
  "cover": slides[0].photo{
    ${imageMeta}
  },
  slides[]->{
    photo{
      ${imageMeta}
    }
  }
}`

export const products = (preview) => `
  curated == true => {
    "products": *[_type == "product" && _id in ^.products[]->_id && wasDeleted != true && isDraft != true${
      preview?.active ? ' && _id in path("drafts.**")' : ''
    }]${product}
  },
  curated == false && defined(category) => {
    "products": *[_type == "product" && category->slug.current == ^.category->slug.current && wasDeleted != true && isDraft != true${
      preview?.active ? ' && _id in path("drafts.**")' : ''
    }]${product}
  },
  curated == false && !defined(category) => {
    "products": *[_type == "product" && wasDeleted != true && isDraft != true${
      preview?.active ? ' && _id in path("drafts.**")' : ''
    }]${product}
  },
  !defined(curated) => {
    "products": *[_type == "product" && category->slug.current == ^.slug.current && wasDeleted != true && isDraft != true${
      preview?.active ? ' && _id in path("drafts.**")' : ''
    }]${product}
  },
  sorting,
  filtering,
  "slug": slug.current,
  category->{
    title,
    "slug": slug.current,
  },
  style->{
    title,
    "slug": slug.current,
  },
  ${productSettings(preview)}
`

// Construct our content "modules" GROQ
export const modules = (preview) =>
  `
  _type,
  _key,
  _type == 'textContent' => {
    content[]{
      ${ptContent}
    },
  },
  _type == 'spotlight' => {
    narrow,
    slideshow,
    items[]{
      ...,
      link {
        ${link},
        "product": page->${product}
      },
      product->${product}
    },
  },
  _type == 'productSlider' => {
    ...,
    link {
      ${link}
    },
    products[]->${product},
  },
  _type == 'storySlider' => {
    ...,
    category->{
      "type": _type,
      "slug": slug.current,
    },
    paginationLimit,
    type == 'curated' => {
      "stories": stories[]->${story}
    },
    type == 'latest' => {
      "stories": *[_type == 'story']${story} | order(coalesce(publishDate, _createdAt) asc)
    },
    type == 'category' => {
      "stories": *[_type == 'story' && category->slug.current == ^.category->slug.current]${story}
    },
  },
  _type == 'artistsWall' => {
    title,
    "artists": *[_type == "artist"]{
      ${artist}
    },
  },
  _type == 'divider' => {
    showStroke,
    space,
  },
  _type == 'accordions' => {
    items[]{
      "id": _key,
      title,
      content[]{
        ${ptContent}
      }
    }
  },
  _type == 'articlesList' => {
    "tags": *[_type == "articleTag"][]{
      title,
      "slug": slug.current,
    } | order(title desc),
    "items": *[_type == "article"][]{
      ${article}
    } | order(publishDate desc),
  },
  _type == 'gallery' => {
    photos[]{
      ...,
      photo {
        ${imageMeta}
      }
    }
  },
  _type == 'video' => {
    ...
  },
  _type == 'quote' => {
    quote,
    byline
  },
  _type == 'relatedArticle' => {
    article->{
      ${article}
    }
  },
  _type == 'split' => {
    title,
    link {
      ${link}
    },
    items[] {
      tag,
      title,
      body,
      link[] {
        ${link}
      },
      image {
        ${imageMeta}
      }
    }
  },
  _type == 'htmlRenderer' => {
    html
  }
  `

// Construct our "site" GROQ
export const site = `
  "site": {
    "title": *[_type == "generalSettings"][0].siteTitle,
    "rootDomain": *[_type == "generalSettings"][0].siteURL,
    "organization": {
      "name": *[_type == "generalSettings"][0].organizationName,
      "phone": *[_type == "generalSettings"][0].organizationPhone,
      "social": *[_type == "generalSettings"][0].social[]{
        icon,
        url,
        showInFooter
      },
      "logo": *[_type == "generalSettings"][0].logo,
    },
    "shop": *[_type == "shopSettings"][0]{
      storeURL,
      cartMessage,
      framePickerTitle,
      framePickerDiscountCode
    },
    "pageOverlays": *[_type == "page" && openInOverlay][]{
      "slug": slug.current
    },
    "header": *[_type == "headerSettings"][0]{
      "promo": *[_type == "promoSettings"][0]{
        enabled,
        display,
        text,
        link->{
          ${page}
        }
      },
      menuDesktop->{
        items[]{
          ${link},
          link {
            ${link}
          },
          columns[] {
            "type": _type,
            title,
            items[]{
              ${link}
            },
            _type == "artistsColumn" => {
              amountOfArtists,
              pinnedArtists[]->{
                ${artist}
              },
              browseAllLink {
                ${link}
              },
              "artists": *[_type == "artist"] | order(title)[0..20]{
                ${artist}
              }
            }
          },
          featured {
            link {
              ${link}
            },
            photo,
            caption
          }
        }
      },
      menuMobile->{
        items[]{
          ${link},
          dropdownItems[]{
            ${link}
          },
        }
      }
    },
    "footer": *[_type == "footerSettings"][0]{
      sellingpoints[]{
        icon {
          ${imageMeta}
        },
        body
      },
      cookiesPage{
        ${link}
      },
      newsletter {
        "id": "footer",
        newsletterID,
        submit,
        successMsg[]{
          ${ptContent}
        },
        errorMsg[]{
          ${ptContent}
        },
        terms[]{
          ${ptContent}
        }
      },
      "blocks": [
        {
          "title": blockTitle2,
          "menu": blockMenu2->{
            items[]{
              ${link}
            }
          }
        },
        {
          "title": blockTitle3,
          "menu": blockMenu3->{
            items[]{
              ${link}
            }
          }
        },
      ]
    },
    "seo": *[_type == "seoSettings"][0]{
      metaTitle,
      metaDesc,
      categoryMetaTitle,
      categoryMetaDesc,
      productMetaTitle,
      productMetaDesc,
      shareTitle,
      shareDesc,
      shareGraphic,
      "favicon": favicon.asset->url,
      "faviconLegacy": faviconLegacy.asset->url,
      touchIcon
    },
    "gtmID": *[_type == "generalSettings"][0].gtmID,
  }
`

// All Products
export const allProducts = (preview) => `
  *[_type == "product" && wasDeleted != true && isDraft != true${
    preview?.active ? ' && _id in path("drafts.**")' : ''
  }]${product} | order(title asc)
`
