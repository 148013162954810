export const sortTypes = [
  { title: 'Sort order', value: 'order' },
  { title: 'Best sellers', value: 'bestsellers' },
  { title: 'Newest', value: 'dateDesc' },
  { title: 'Oldest', value: 'dateAsc' },
  { title: 'Discounted', value: 'discounted' },
  { title: 'Price: High to Low', value: 'priceDesc' },
  { title: 'Price: Low to High', value: 'priceAsc' },
  { title: 'Alpha: A – Z', value: 'alphaAsc' },
  { title: 'Alpha: Z – A', value: 'alphaDesc' }
]
